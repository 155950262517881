import {userTaskStores} from "../stores/TaskStores";
import React, {useEffect, useRef, useState} from "react";
import {LazyLoadImage} from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

import {Button, Carousel, IconButton, Spinner} from "@material-tailwind/react";
import {
    faChevronLeft,
    faChevronRight,
} from "@fortawesome/free-solid-svg-icons";
import 'react-circular-progressbar/dist/styles.css';
import {
    faArrowDown,
    faArrowRight, faCircleExclamation, faCircleXmark, faCopy, faExclamation,
    faTrashCan
} from "@fortawesome/free-solid-svg-icons";
import {TaskProcessBar} from "../components/task/TaaskProcessBar";
import {useNavigate, useParams, useSearchParams} from "react-router-dom";
import {DeleteDialog} from "../components/task/DeleteDoialog";
import {PageLoading} from "../components/common/PageLoading";
import toast from "react-hot-toast";
import {Downloading} from "../components/common/Downloading";
import {TaskEntity} from "../model/task/TaskEntity";
import {GeneratingView} from "../components/task/GeneratingView";
import {NSFW} from "../components/task/NSFW";
import {Error} from "../components/task/Error";
import {ButtonActionPanel, setCarouselIndex} from "../components/task/ButtonActionPanel";
import {ButtonCloseTask} from "../components/task/ButtonCloseTask";
import AiImage from "../components/task/AiImage";
import {Segment} from "../api/task/TaskApi";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {logEvent} from "firebase/analytics";
import {analytics, SOURCE_ID_KEY} from "../App";
import {EVENT_PAGE_TASK_DETAIL} from "../util/Event";
import i18n from "../react-i18next/i18n";


export interface IProps {
    task?: TaskEntity
    showClose?: boolean,
    onDismiss?: () => void,
    onDeleted?: () => void,
}

export function TaskDetail(props: IProps) {
    const {lng} = useParams();
    i18n.changeLanguage(lng);
    const {task, onDismiss, showClose, onDeleted} = props;
    let [searchParams, setSearchParams] = useSearchParams();
    const navigate = useNavigate()
    let taskId = searchParams.get("id");
    const batchSize = searchParams.get("batch_size");
    if (task) {
        taskId = task.task_id
    }

    const {
        taskEntity,
        showNsfwWarm,
        resetStatus,
        fetchTaskInfo,
        setImageLoad,
        showDeleteDialog,
        setShowDeleteDialog,
        deleteTask,
        redirectJump,
        deleting,
        initTask,
        setShowTaskClose,
        setActiveItem,
    } = userTaskStores((state) => ({
        taskEntity: state.taskEntity,
        startGenerated: state.startGenerated,
        queryGenerateStatus: state.queryGenerateStatus,
        errMsg: state.errMsg,
        confirmContent: state.confirmContent,
        showNsfwWarm: state.showNsfwWarm,
        resetStatus: state.resetStatus,
        fetchTaskInfo: state.fetchTaskInfo,
        setImageLoad: state.setImageLoad,
        imageLoaded: state.imageLoaded,
        showDeleteDialog: state.showDeleteDialog,
        setShowDeleteDialog: state.setShowDeleteDialog,
        deleteTask: state.deleteTask,
        redirectJump: state.redirectJump,
        deleting: state.deleting,
        initTask: state.initTask,
        setShowTaskClose: state.setShowTaskClose,
        setActiveItem:state.setActiveItem
    }))

    const containerRef = useRef(document.createElement('div'));
    const [contentWidth, setContentWidth] = useState(0)
    const [contentHeight, setContentHeight] = useState(0)


    useEffect(() => {
        if (task) {
            initTask(task)
            console.log(`initTask:entity:${JSON.stringify(taskEntity)}  status:${taskEntity.status}`)
            setShowTaskClose(showClose ? showClose : false)
        } else {
            fetchTaskInfo(taskId)
        }
        if (taskId) {
            // queryGenerateStatus()
        }
    }, [taskId])

    useEffect(() => {
        if (redirectJump) {
            if (!taskEntity) {
                resetStatus()
                navigate(`/home`)
            } else if (onDeleted) {
                onDeleted()
            }
        }
    }, [redirectJump])

    useEffect(() => {
        const sourceId = localStorage.getItem(SOURCE_ID_KEY)
        logEvent(analytics, EVENT_PAGE_TASK_DETAIL  , {
            source_id:sourceId,
            page_path: EVENT_PAGE_TASK_DETAIL,
        });
        const handleResize = () => {
            if (containerRef.current) {
                const container = containerRef.current;
                const height = container.offsetHeight;
                const screenWidth = container.offsetWidth;
                let contentWidth = height / (3 / 2);
                if (contentWidth > screenWidth) {
                    contentWidth = screenWidth
                }
                console.log('contentHeight offset:' + container.offsetHeight)
                console.log('contentHeight offset:' + container.offsetHeight)
                console.log('contentHeight:' + height + ' contentWidth:' + contentWidth)
                setContentHeight(height)
                setContentWidth(contentWidth)
            }
        }
        window.addEventListener('resize', handleResize);
        handleResize();
        return () => {
            window.removeEventListener('resize', handleResize);
        }
    }, []);

    const handleUnmount = () => {
        resetStatus()
        console.log('handleUnmount')
    }

    useEffect(() => {
        return handleUnmount
    }, []);

    const showProcess = () => {
        if (taskEntity.status === 'GENERATING')
            return true
        else return false
    }

    const showNSFWContent = (item:Segment) => {
        if (item && item.nsfw_result) {
            return true
        } else {
            return false
        }
    }

    const showGeneratedResult = () => {
        if (taskEntity.status === 'GENERATED' && !showNsfwWarm)
            return true
        else return false
    }
    console.log(`taskEntity: ${taskEntity.status}`)


    const showError = () => {
        if (taskEntity.status === 'FAILED') {
            return true
        } else {
            return false
        }
    }

    const hideDialog = () => {
        setShowDeleteDialog(false)
    }

    const handleConfirm = () => {
        deleteTask()
    }

    const dismiss = () => {
        if (onDismiss) {
            onDismiss()
        } else {
            navigate(`/create`)
        }
    }

    const showCover = ()=>{
        if(taskEntity && taskEntity.projectEntity && taskEntity.projectEntity.cover_img){
            if(taskEntity.projectEntity.cover_img.length>1){
                return true
            }
        } else {
            return false
        }
    }

    const contentClick = () => {
        console.log('contentClick')
    }

    const generationTime = ()=>{
        if(batchSize != null && batchSize != undefined){
            // @ts-ignore
            return batchSize * 23000
        } else {
            return 23000
        }
    }

    const showArrow = ()=>{
        if(taskEntity && taskEntity.projectEntity && taskEntity.projectEntity.project_segments){
            if(taskEntity.projectEntity.project_segments.length > 1){
                return true
            }
        }
        return false
    }
    return (<div
        onClick={dismiss}
        ref={containerRef}
        className="flex flex-row h-screen justify-start items-center flex-col pl-[200px] pr-[200px] w-min[600px] bg-dark-bg">

        <DeleteDialog open={showDeleteDialog} onConfirm={() => {
            handleConfirm()
        }} onCancel={() => {
            hideDialog()
        }} deleting={deleting}></DeleteDialog>
        <div className="relative">
            {!showError() && <PageLoading></PageLoading>}
            {showError() && <Error></Error>}
            {showProcess() && <GeneratingView duration={generationTime()}></GeneratingView>}
            <div
                onClick={(e) => {
                    e.stopPropagation()
                    contentClick()
                }
                }
                style={{width: contentWidth + 'px', height: contentHeight + 'px'}}
                className={`flex flex-col h-screen`}>
                {showGeneratedResult() &&
                    <Carousel placeholder='' loop={true} autoplay={false}
                              navigation={({ setActiveIndex, activeIndex, length }) => {
                                  console.log(`setActiveIndex${activeIndex}`)
                                  setCarouselIndex(activeIndex)
                                  return (
                                  <div className="absolute bottom-4 left-2/4 z-50 flex -translate-x-2/4 gap-2">
                                      {new Array(length).fill("").map((_, i) => (
                                          <span
                                              key={i}
                                              className={`block h-1 cursor-pointer rounded-2xl transition-all content-[''] ${
                                                  activeIndex === i ? "w-8 bg-white" : "w-4 bg-white/50"
                                              }`}
                                              onClick={() => {
                                                  setActiveIndex(i)}}
                                          />
                                      ))}
                                  </div>
                              )}}
                              prevArrow={({ handlePrev }) => (
                                  <IconButton
                                      placeholder={''}
                                      variant="text"
                                      color="white"
                                      size="lg"
                                      onClick={()=>{
                                          handlePrev()}}
                                      className={`!absolute top-2/4 left-4 -translate-y-2/4 rounded-[25px] ${showArrow()?'visible':'invisible'}`}
                                  >
                                      <FontAwesomeIcon size={"xl"} color={'white'} icon={faChevronLeft}></FontAwesomeIcon>
                                  </IconButton>
                              )}
                              className=" rounded-[0px] lg:rounded-[20px] mb-[100px]"
                              nextArrow={({ handleNext }) => {
                                  return (
                                  <IconButton
                                      placeholder={''}
                                      variant="text"
                                      color="white"
                                      size="lg"
                                      onClick={()=>{
                                          handleNext()
                                      }
                                  }
                                      className={`!absolute top-2/4 !right-4 -translate-y-2/4 rounded-[25px]  ${showArrow()?'visible':'invisible'}`}
                                  >
                                      <FontAwesomeIcon size={"xl"} color={'white'} icon={faChevronRight}></FontAwesomeIcon>
                                  </IconButton>
                              )}}
                    >


                        {showCover() && <AiImage img_url={taskEntity.projectEntity?.cover_img} onImageLoaded={() => {setImageLoad(true)}}></AiImage>}

                        {(taskEntity && taskEntity.projectEntity && taskEntity.projectEntity.project_segments) &&
                            taskEntity.projectEntity.project_segments.map((item) => {
                                if(showNSFWContent(item)){
                                    return (<NSFW></NSFW>)
                                } else {
                                    return (<AiImage img_url={item.image_url} onImageLoaded={() => {
                                            setImageLoad(true)
                                        }}></AiImage>
                                    )
                                }
                            })
                        }
                    </Carousel>
                }
                    {showGeneratedResult() && <ButtonActionPanel onDismiss={onDismiss}></ButtonActionPanel>}
            </div>

        </div>
        {showClose && <div className='absolute top-[0px] right-[100px]'>
         <ButtonCloseTask onDismiss={dismiss}></ButtonCloseTask>
        </div>
        }
        <div className='absolute top-[70px] right-[20px] lg:hidden'>
            <ButtonCloseTask onDismiss={dismiss}></ButtonCloseTask>
        </div>
    </div>)
}
