import {buildStyles, CircularProgressbar} from "react-circular-progressbar";
import {useEffect, useState} from "react";


interface IProps {
    duration: number
    processPercentage?: number
}
export function TaskProcessBar(props:IProps = {
    duration: 0,
    processPercentage: 0
}) {
    const {duration,processPercentage} = props
    const [percentage, setPercentage] = useState(0);
    useEffect(() => {
        if(duration == 0) return
        const timer = setInterval(() => {
            setPercentage(prevPercentage => {
                if(prevPercentage >= 99) {
                    clearInterval(timer);
                    return 99;
                }
                return prevPercentage + 1;
            });
        }, duration/99);
        return () => clearInterval(timer);
    }, [duration]);

    return (<CircularProgressbar value={duration != 0 ? percentage : processPercentage || 1} text={`${duration != 0 ? percentage : processPercentage || 1}%`} className="w-[130px] h-[130px]"
                                 styles={buildStyles({
                                     // Rotation of path and trail, in number of turns (0-1)
                                     rotation: 1,

                                     // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
                                     strokeLinecap: 'round',

                                     // Text size
                                     textSize: '22px',

                                     // How long animation takes to go from one percentage to another, in seconds
                                     pathTransitionDuration: 0.5,

                                     // Can specify path transition in more detail, or remove it entirely
                                     // pathTransition: 'none',

                                     // Colors
                                     pathColor: `rgb(145, 112, 240)`,
                                     textColor: '#ccc',
                                     trailColor: '#d6d6d6',
                                     backgroundColor: 'rgb(145, 112, 240)',
                                 })}/>)
}