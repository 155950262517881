import {TaskEntity} from "../model/task/TaskEntity";
import {create} from "zustand";
import toast from "react-hot-toast";
import {apiService} from "../service/Api";
import {ProjectEntity, Segment, TasksQueryRequest} from "../api/task/TaskApi";
import {STORAGE_FACE_MODEL_LIST} from "./CreateStores";
import {TaskType} from "../model/create/CreateEntity";


export const STORAGE_CACHE_CREATIONS_FIRST_PAGE = 'STORAGE_CACHE_CREATIONS_FIRST_PAGE'

export type Action = {
    deleteTask: () => void
    fetchTasks: (page?:number,loadMore?:boolean) => void,
    fetchTaskFromCache:()=>void,
    showDelete:(taskId:string)=>void,
    resetDeleteResult:()=>void,
    hideDelete:()=>void,
    setShowDetail:(show:boolean,task:TaskEntity|undefined)=>void,
    refreshTasks:()=>void,
    loadMore:()=>void,
    resetStatus:()=>void,
}

export type State = {
    projects: ProjectEntity[]
    taskId: '',
    querying:boolean,
    showQuerying: boolean,
    showEmpty: boolean,
    showError: boolean,
    errorMsg: string,
    deleting:boolean,
    deleteSuccess:boolean,
    showDeleteDialog:boolean,
    deleteTaskId:string,
    detailTask?:TaskEntity,
    showDetail:boolean,
    page:number,
    has_next_page:boolean
    lastPage:boolean,
    cacheProjects: ProjectEntity[]
}

const initStatus: State = {
    projects: [],
    cacheProjects:[],
    showQuerying: false,
    querying:false,
    taskId: '',
    showEmpty: false,
    showError: false,
    errorMsg: '',
    showDeleteDialog:false,
    deleting:false,
    deleteTaskId:'',
    showDetail:false,
    detailTask:undefined,
    deleteSuccess:false,
    page:1,
    lastPage:false,
    has_next_page:true,
}

export const userCreationStores = create<Action & State>((set, get) => ({
    ...JSON.parse(JSON.stringify(initStatus)),
    deleteTask() {
        const deleteTaskId =get().deleteTaskId
        set(state => ({
            deleting:true
        }))
        apiService.Delete({
            id: deleteTaskId
        }).then((response) => {
            if (response && response.data && response.code === 200) {
                set(state => ({
                    deleteSuccess:true,
                    showDeleteDialog: false,
                    deleting:false,
                }))
                toast.success('Delete Success')
            } else {
                set(state => ({
                    deleting:false,
                    showDeleteDialog:false
                }))
                toast.error('Delete Failed')
            }
        }).catch(() => {
            set(state => ({
                deleting:false,
                showDeleteDialog:false
            }))
            toast.error('Delete Failed')
        })
    },

    showDelete(taskId:string) {
      set(state=>({
          showDeleteDialog:true,
          deleteTaskId:taskId
      }))
    },
    hideDelete() {
        set(state => ({
            showDeleteDialog: false,
            deleteTaskId: ''
        }))
    },
    resetDeleteResult(){
        set(state => ({
            deleteSuccess: false,
        }))
    },
    setShowDetail(show:boolean,task:TaskEntity){
        set(state => ({
            showDetail: show,
            detailTask:task
        }))

    },
    async refreshTasks(){
        const projects:ProjectEntity[] = []
        const response = await apiService.QueryTasks()
        if (response.data && response.data && response.code == 200) {
            if (response.data && response.data.data.length !== 0) {
                response.data.data.map((project) => {
                    if (project) {
                        projects.push(project)
                        set(state => ({
                            projects: projects,
                        }))
                    }
                })
            }
        }
    },
    async loadMore (){
        console.log(`handleScroll loadMore querying:${get().querying}`)
        if(!get().has_next_page){
            console.log(`has_next:${get().has_next_page}`)
            return
        }
        const loadedProject:ProjectEntity[] = get().projects
        const projects:ProjectEntity[] = loadedProject
        const currentPage = get().page+1
        const req:TasksQueryRequest = {
            project_type:TaskType.image,
            page:currentPage,
            size:20,
        }
        set(state => ({
            querying:true
        }))
        const response = await apiService.QueryTasks(req)
        if (response.data && response.data && response.code == 200) {
            if (response.data && response.data.data.length !== 0 || (loadedProject)) {

                response.data.data.map((project) => {
                    if (project) {
                        projects.push(project)
                    }
                })
                set(state => ({
                    projects: projects,
                    showQuerying: false,
                    querying:false,
                    showError: false,
                    showEmpty:false,
                    page:response.data.page,
                    has_next_page:response.data.has_next
                }))
            } else {
                set(state => ({
                    showEmpty: true,
                    querying:false,
                    showQuerying: false,
                    errorMsg:'No Creations',
                    showError: false,
                }))
            }
        } else {
            set(state => ({
                projects: [],
                showQuerying: false,
                querying:false,
                showEmpty:false,
                showError: true,
                errMsg: "Ops:something wrong"
            }))
        }
    },
    fetchTaskFromCache(){
        const cacheProjects = localStorage.getItem(STORAGE_CACHE_CREATIONS_FIRST_PAGE)
        if(cacheProjects) {
            const projects = JSON.parse(cacheProjects);
            set(state=>({
                cacheProjects:projects,
                showQuerying: false,
                querying:false
            }))
        }
    },
    async fetchTasks(page:number, loadMore:boolean) {
        const projects:ProjectEntity[] = loadMore? get().projects :[]
        set(state => ({
            showQuerying: true,
            querying:true
        }))
        const currentPage = get().page
        const req:TasksQueryRequest = {
            page:page?page:currentPage,
            project_type:TaskType.image,
            size:20,
        }
        const response = await apiService.QueryTasks(req)
        if (response.data && response.data && response.code == 200) {
            if (response.data && response.data.data.length !== 0) {
                //本地缓存第一页数据
                if(req.page === 1 && response.data && response.data.data.length !== 0){
                    localStorage.setItem(STORAGE_CACHE_CREATIONS_FIRST_PAGE,JSON.stringify(response.data.data))
                }
                response.data.data.map((project) => {
                    if (project) {
                        projects.push(project)
                    }
                })
                set(state => ({
                    projects: projects,
                    showQuerying: false,
                    showError: false,
                    showEmpty:false,
                    querying:false,
                    page:response.data.page,
                    has_next_page:response.data.has_next,
                }))
            } else {
                set(state => ({
                    showEmpty: true,
                    showQuerying: false,
                    errorMsg:'No Creations',
                    showError: false,
                    querying:false
                }))
            }
        } else {
            set(state => ({
                projects: [],
                showQuerying: false,
                querying:false,
                showEmpty:false,
                showError: true,
                errMsg: "Ops:something wrong"
            }))
        }
    },
    resetStatus: () => {
        set(JSON.parse(JSON.stringify(initStatus)))
    },

}))