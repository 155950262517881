import axios from "axios";
import {
  CreateRequest,
  CreateResponse,
  CreateVideoRequest,
  GetFacesRequest,
  GetFacesResponse,
  MaterialRequest,
  MaterialResponse,
  PreSignParamsUrlData,
  PreSignParamsUrlDataResponse,
  QueryFacesRequest,
  QueryFacesResponse,
  UploadRequest,
  UploadResponse,
  UploadVideoResponse,
} from "../api/create/CreateApi";
import {
  TaskDeleteRequest,
  TaskDeleteResponse,
  TaskQueryRequest,
  TaskQueryResponse,
  TasksQueryRequest,
  TasksQueryResponse,
} from "../api/task/TaskApi";
import {
  AuthRequest,
  AuthResponse,
  CreatePaymentRequest,
  CreatePaymentResponse,
  GetProductRequest,
  GetProductResponse,
  GetUserRequest,
  GetUserResponse,
  LoginRequest,
  LoginResponse,
} from "../api/user/UserApi";
import { API_TOKEN, axiosInstance } from "./Api";

export default class ApiServices<T> {
  private request: any = () => {
    throw new Error("StoryApiService.request is undefined");
  };
  private baseURL: string | ((path: string) => string) = "";

  constructor(options?: {
    baseURL?: string | ((path: string) => string);
    request?<R>(
      params: {
        url: string;
        method: "GET" | "DELETE" | "POST" | "PUT" | "PATCH";
        data?: any;
        params?: any;
        headers?: any;
      },
      options?: T
    ): Promise<R>;
  }) {
    this.request = options?.request || this.request;
    this.baseURL = options?.baseURL || "";
  }

  genBaseURL(path: string) {
    return typeof this.baseURL === "string"
      ? this.baseURL + path
      : this.baseURL(path);
  }

  QueryMaterial(req?: MaterialRequest, options?: T): Promise<MaterialResponse> {
    const url = this.genBaseURL("/v1/materials/");
    const method = "GET";
    return this.request({ url, method }, options);
  }

  CreateTask(req?: CreateRequest, options?: T): Promise<CreateResponse> {
    const url = this.genBaseURL("/v1/projects/create");
    const method = "POST";
    const data = req;
    return this.request({ url, method, data }, options);
  }

  SubmitVideoTask(
    req?: CreateVideoRequest,
    options?: T
  ): Promise<CreateResponse> {
    const url = this.genBaseURL("/v1/projects/video/swap");
    const method = "POST";
    const data = req;
    return this.request({ url, method, data }, options);
  }

  QueryTask(req?: TaskQueryRequest, options?: T): Promise<TaskQueryResponse> {
    const url = this.genBaseURL(`/v1/projects/${req?.project_id}`);
    const method = "GET";
    return this.request({ url, method }, options);
  }

  QueryTasks(
    req?: TasksQueryRequest,
    options?: T
  ): Promise<TasksQueryResponse> {
    const url = this.genBaseURL(`/v1/projects/fetch`);
    const method = "GET";
    const params = req;
    return this.request({ url, method, params }, options);
  }

  Delete(req?: TaskDeleteRequest, options?: T): Promise<TaskDeleteResponse> {
    const url = this.genBaseURL(`/v1/projects/delete/${req?.id}`);
    const method = "DELETE";
    const data = req;
    return this.request({ url, method, data }, options);
  }

  Auth(req?: AuthRequest, options?: T): Promise<AuthResponse> {
    const url = this.genBaseURL(`/v1/users/auth`);
    const method = "POST";
    const data = req;
    return this.request({ url, method, data }, options);
  }

  GetUserInfo(req?: GetUserRequest, options?: T): Promise<GetUserResponse> {
    const url = this.genBaseURL(`/v1/users/me`);
    const method = "GET";
    return this.request({ url, method }, options);
  }

  Login(req?: LoginRequest, options?: T): Promise<LoginResponse> {
    const url = this.genBaseURL(`/v1/users/me`);
    const method = "GET";
    return this.request({ url, method, req }, options);
  }

  CreatePayment(
    req: CreatePaymentRequest,
    options?: T
  ): Promise<CreatePaymentResponse> {
    const url = this.genBaseURL(`/v1/subscription/payment`);
    const method = "POST";
    const data = req;
    return this.request({ url, method, data }, options);
  }

  GetProducts(
    req: GetProductRequest,
    options?: T
  ): Promise<GetProductResponse> {
    const url = this.genBaseURL("/v1/subscription/products");
    const method = "GET";
    const data = req;
    return this.request({ url, method }, options);
  }

  UploadFace(req: UploadRequest, options?: T): Promise<UploadResponse> {
    const url = this.genBaseURL("/v1/faces/upload");
    const formData = new FormData();
    formData.append("file", req.file);
    return axiosInstance.post(url, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: "Bearer " + API_TOKEN,
      },
      method: "POST",
    });
  }

  UploadVideo(req: UploadRequest, options?: T): Promise<UploadVideoResponse> {
    const url = this.genBaseURL("/v1/faces/video");
    const formData = new FormData();
    formData.append("file", req.file);
    return axiosInstance.post(url, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: "Bearer " + API_TOKEN,
      },
      method: "POST",
    });
  }

  QueryFaces(req: QueryFacesRequest, options?: T): Promise<QueryFacesResponse> {
    const url = this.genBaseURL("/v1/faces/query");
    const method = "GET";
    const params = req;
    return this.request({ url, method, params }, options);
  }

  GetFaces(req: GetFacesRequest, options?: T): Promise<GetFacesResponse> {
    const url = this.genBaseURL("/v1/faces/fetch");
    const method = "GET";
    const data = req;
    return this.request({ url, method }, options);
  }

  // 获取 aws 上传 url
  GetAwsToken(): Promise<PreSignParamsUrlDataResponse> {
    const url = this.genBaseURL("/v1/faces/video/url/presigned");
    const method = "GET";
    return this.request({ url, method });
  }

  UploadAwsanalysisUrl(awsUrl: string): Promise<UploadVideoResponse> {
    const url = this.genBaseURL("/v1/faces/video/analysis");
    const method = "POST";
    const data = {
      video_url: awsUrl,
    };
    return this.request({ url, method, data });
  }

  // 通过授权 aws url 上传文件。
  async UploadFileByPresignedUrl(
    preSignParamsUrlData: PreSignParamsUrlData | null,
    file: File,
    callback: (progress: number, estimatedRemainingTime: string) => void
  ): Promise<any> {
    if (!preSignParamsUrlData) {
      return;
    }

    const formData = new FormData();
    formData.append("key", preSignParamsUrlData.pre_sign_params.key);
    formData.append(
      "x-amz-credential",
      preSignParamsUrlData.pre_sign_params.x_amz_credential
    );
    formData.append(
      "x-amz-date",
      preSignParamsUrlData.pre_sign_params.x_amz_date
    );
    formData.append(
      "x-amz-signature",
      preSignParamsUrlData.pre_sign_params.x_amz_signature
    );
    formData.append("policy", preSignParamsUrlData.pre_sign_params.policy);
    formData.append(
      "x-amz-algorithm",
      preSignParamsUrlData.pre_sign_params.x_amz_algorithm
    );
    formData.append("file", file);
    const headers = {
      "Content-Type": "multipart/form-data",
    };
    const axiosInner = axios.create({
      timeout: 180000,
    });
    let lastUpdateTime = 0;
    let lastLoaded = 0;
    callback(0, 'Calculating...');
    return axiosInner.post(preSignParamsUrlData.url, formData, {
      headers: headers,
      method: "POST",
      onUploadProgress: (progressEvent) => {
        if(lastUpdateTime == 0){
          lastUpdateTime = Date.now();
          callback(0, 'Calculating...');
          return
        }
        // 首次计算包含建链时间 有加大波动，所以跳过
        const now = Date.now();
        const currentTimeElapsed = (now - lastUpdateTime);
        // 计算本次上传花费的时间内上传了多少数据
        const avgSpeed = (progressEvent.loaded / currentTimeElapsed) * 1000
        // 当前已上传的总百分比
        const percentCompleted = Math.round(
          (progressEvent.loaded * 100) / (progressEvent.total || 100)
        );
        // console.log(`uploaded total : ${progressEvent.total||progressEvent.loaded}`)
        // 剩余需上传的字节数
        const remainingBytes = (progressEvent.total||progressEvent.loaded) - progressEvent.loaded;
        // console.log(`uploaded avgSpeed : ${avgSpeed}`)
        // console.log(`uploaded remainingBytes : ${remainingBytes}`)

        // 根据当前速度估算剩余时间（毫秒），注意处理除以0的情况
        let estimatedRemainingTime = remainingBytes / avgSpeed;
        if (avgSpeed !== 0) {
          estimatedRemainingTime = remainingBytes / avgSpeed;
        } else {
          // 如果avgSpeed为0，说明还没开始上传或上传速度极慢，可设定为0或不处理
          estimatedRemainingTime = 0; // 或者可以显示为"计算中"之类的提示
        }
        lastLoaded = progressEvent.loaded;
        const estimatedRemainingTimeString = `${Math.floor((estimatedRemainingTime))}s`;
        callback(percentCompleted, estimatedRemainingTimeString);
      },
    });
  }
}
