import {
    AuthRequest,
    AuthResponse,
    GetUserRequest,
    GetUserResponse,
    LoginRequest,
    LoginResponse
} from "../api/user/UserApi";
import {UserEntity} from "../model/UserEntity";
import {create} from "zustand";
import {API_TOKEN, apiService, clearToken, STORAGE_TOKEN, updateToken} from "../service/Api";
import toast from "react-hot-toast";
import ApiServices from "../service/ApiServices";
import {INVITE_CODE_KEY} from "../App";


const STORAGE_USER_INFO = 'USER_INFO'

type Action = {
    authGoogle: (token: string) => AuthResponse,
    loginGoogle: (login: LoginRequest) => LoginResponse,
    initUserInfo: () => void,
    logout: () => void,
    fetchUserInfo: ()=> void,
    resetRefresh:()=>void

    getUserMember:()=> number
}

type State = {
    loading: boolean,
    userEntity: UserEntity | undefined
    needRefresh:boolean
}

const initStatus: State = {
    userEntity: undefined,
    loading: false,
    needRefresh:true,
}

export const useUserStores = create<Action & State>((set, get) => ({
    ...JSON.parse(JSON.stringify(initStatus)),
    async authGoogle(token: string) {
        set(state => ({
            loading: true
        }))
        const inviteCode = localStorage.getItem(INVITE_CODE_KEY)
        const authReq: AuthRequest = {
            token: token,
            channel: 'google',
            inviteCode: inviteCode
        }
        await apiService.Auth(authReq).then((authResponse) => {
            console.log(`auth resp:${JSON.stringify(authResponse)}`)
            if (authResponse.code == 200) {
                const loginReq: LoginRequest = {}
                updateToken(authResponse.data.token)
                return apiService.Login(loginReq)
            } else {
                toast.error('Auth Failed')
            }
        }).then((loginResponse) => {
            if (loginResponse && loginResponse.code == 200) {
                const userEntity = loginResponse.data
                set(state => ({
                    userEntity: loginResponse.data,
                    loading: false
                }))
                localStorage.setItem(STORAGE_USER_INFO, JSON.stringify(userEntity))
                toast.success('Login Success')
            } else {
                toast.error('Login Failed')
                set(state => ({
                    loading: false
                }))
            }
        }).catch((err) => {
            console.log(`auth resp:${JSON.stringify(err)}`)
            toast.error('Auth Failed')
            set(state => ({
                loading: false
            }))
        })
    },
    initUserInfo() {
        const userInfoJson = localStorage.getItem(STORAGE_USER_INFO)
        const userToken = localStorage.getItem(STORAGE_TOKEN)
        if (userToken) {
            updateToken(userToken)
        }
        if (userInfoJson) {
            const userInfo: UserEntity = JSON.parse(userInfoJson)
            set(state => ({
                userEntity: userInfo
            }))
        }
    },
    fetchUserInfo() {
        const req: GetUserRequest = {}
        apiService.GetUserInfo(req).then((resp: GetUserResponse) => {
            if (resp.code == 200 && resp.data) {
                set(state => ({
                    userEntity: resp.data,
                    needRefresh:false
                }))
                localStorage.setItem(STORAGE_USER_INFO, JSON.stringify(resp.data))
            }
        })

    },
    logout() {
        const userInfoJson = localStorage.removeItem(STORAGE_USER_INFO)
        clearToken()
        set(state => ({
            userEntity: undefined
        }))
    },
    resetRefresh(){
        set(state => ({
            needRefresh: true
        }))
    },
    getUserMember(){
        const userInfo = get().userEntity
        if(userInfo && userInfo.entitlement && userInfo.entitlement.member_level){
            return userInfo.entitlement.member_level[0]
        } else {
            return 0
        }
    }
}))