import {useCreateStores} from "../../stores/CreateStores";
import {StylesEntity} from "../../model/create/StylesEntity";
import { useTranslation } from "react-i18next";

export function StyleSelectorBox() {
    const {createEntity, setStyle, modelStyle} = useCreateStores((state) => ({
        createEntity: state.createEntity,
        modelStyle: state.configEntity.modelStyle,
        setStyle: state.setStyle
    }))
    const {t} = useTranslation()

    return (<div
        className="flex flex-row justify-between  w-full  h-500 border-gray border-2 rounded-[10px] h-[100px] mt-[15px]">

        <div className="flex bg-transparent flex-col justify-center items-start ml-[10px] w-[130px] bg-dark-bg">
            <div className="text-white font-bold text-[20px] select-none">{t('girl_style')}</div>
        </div>
        <div className="flex flex-row items-center space-x-3 mr-[10px]">
            {modelStyle && modelStyle.map((styleEntity: StylesEntity) => {
                const selected = styleEntity.index === createEntity.model.index
                return <div className="relative select-none">
                    <div
                        onClick={()=>{setStyle(styleEntity)}}
                        className={`cursor-pointer flex flex-row items-end w-[80px] h-[80px] border-${selected?'primary-color':'gray'} border-[2px] rounded-[10px]`}>
                        <img className="relative rounded-[8px] w-full h-full object-cover object-center"
                             src={styleEntity.image}></img>
                    </div>
                    <div
                        className="absolute bottom-[0px] text-white bg-gray-a w-full rounded-br-[10px]  rounded-bl-[10px] text-[15px] font-bold">
                        {styleEntity.name}
                    </div>
                </div>
            })}


        </div>
    </div>)
}