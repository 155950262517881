import React, { useEffect, useState } from "react";
import { useFaceEditStores } from "../../stores/FaceEditStores";
import { Grid } from "@mui/material";
import { FaceEditProjectItem } from "../../components/face-edit/FaceEditProjectItem";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowUpFromBracket } from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";

export function VideoProjectList() {
  const { showEmpty, fetchTasks, fetchTaskFromCache, projects } =
    useFaceEditStores((state) => ({
      showEmpty: state.showEmpty,
      fetchTasks: state.fetchTasks,
      fetchTaskFromCache: state.fetchTaskFromCache,
      projects: state.projects,
    }));

  useEffect(() => {
    fetchTaskFromCache();
    fetchTasks();
  }, []);
  const {t} = useTranslation()
  return (
    <div className="flex flex-col lg:w-1/4 w-full h-[300px] lg:h-full  bg-dark-bg rounded-[15px]   pl-[10px] pr-[10px] mb-[10px]">
      <div className="select-none text-white pt-[10px] text-left relative  text-[20px] font-bold">
        {t('recently_used')}
      </div>
      {!showEmpty && (
        <div
          className={
            "hidden lg:block lg:pt-[10px] overflow-hidden overflow-y-auto"
          }
        >
          <Grid
            container
            spacing={{ xs: 0, md: 0 }}
            columns={15}
            direction="row"
          >
            {projects &&
              projects.map((item, index) => {
                return (
                  <Grid xs={5} sm={3} md={5} key={index}>
                    <FaceEditProjectItem
                      projectItem={item}
                    ></FaceEditProjectItem>
                  </Grid>
                );
              })}
          </Grid>
        </div>
      )}

      {!showEmpty && (
        <div className="lg:hidden visible flex overflow-x-auto scroll-snap-type-x scrollbar-track-transparent scrollbar-thumb-blue-500 items-center pt-[5px]">
          {projects &&
            projects.map((item, index) => {
              return (
                <div className="w-[104px]  scroll-snap-align-start">
                  <div className="h-[1px] w-[104px]  text-white "></div>
                  <FaceEditProjectItem projectItem={item}></FaceEditProjectItem>
                </div>
              );
            })}
        </div>
      )}
      {showEmpty && (
        <div className="text-white text-[18px] text-center w-full mt-[12px] lg:mt-[28px]">
          {t('please_upload_your_video_file_then_enjoy_your_creation')}
        </div>
      )}
    </div>
  );
}
