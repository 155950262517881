import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faExclamation} from "@fortawesome/free-solid-svg-icons";
import React from "react";
import {userTaskStores} from "../../stores/TaskStores";

export function Error() {
    const {errMsg} = userTaskStores((state) => ({
        errMsg: state.errMsg
    }))
    return (<div className="absolute flex flex-col inset-0 flex items-center justify-center">
        <div className="flex flex-row justify-center items-center h-[30px]">
            <FontAwesomeIcon color={'red'} size={"lg"} icon={faExclamation}/>
            <div className="text-red-600 text-[18px]  ml-[10px]">{errMsg}</div>
        </div>
    </div>)
}