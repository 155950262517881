import React, {useState} from "react";
import {Button, Spinner} from "@material-tailwind/react";
import {useNavigate, useParams} from "react-router-dom";
import {Footer} from "../../components/common/Footer";
import {HomeHintDialog} from "./HomeHintDialog";
import {useTranslation} from "react-i18next";
import i18n from "../../react-i18next/i18n";

const userAgreeAgreement = "USER_AGREE_AGREEMENT";

export function Home() {
    const {lng} = useParams();
    i18n.changeLanguage(lng);
    const userHaveAgree = localStorage.getItem(userAgreeAgreement) || false;
    console.log("userHaveAgree = " + userHaveAgree);
    const [showAgreement, setShowAgreement] = useState(!userHaveAgree);
    const navigator = useNavigate();
    const {t} = useTranslation()
    return (
        <div className="flex-col w-full h-screen overflow-y-auto ">
            <div className="flex-col h-screen bg-dark-bg">
                <div className="text-primary-color text-[32px] lg:text-[44px]  font-bold select-none">
                    {t('ai_magic_tool')}
                </div>
                <div className="text-white text-[22px]lg:text-[18px]  select-none mt-[10px]">
                    {t('makes_your_dream_character_come_to_reality')}
                </div>
                <img
                    src={require("../../../src/assets/home_banner.webp")}
                    className="w-full select-none mt-[50px]"
                ></img>
                <div className="flex justify-center w-full absolute bottom-0 mb-[70px]">
                    <Button
                        placeholder={""}
                        className="normal-case relative bg-gradient-to-r from-blue-500 to-pink-500 flex flex-row justify-center items-center text-[22px] bg-gray h-500 border-gray border-4 rounded-[30px] h-[60px] mt-[30px]"
                    >
                        <div
                            className="flex gap-8"
                            onClick={() => {
                                navigator("/create");
                            }}
                        >
                            {t('try_it_now')}
                        </div>
                    </Button>
                </div>
            </div>
            <Footer></Footer>
            <HomeHintDialog
                show={showAgreement}
                msg={""}
                title={""}
                onCancel={function (): void {
                    localStorage.setItem(userAgreeAgreement, "true");
                    setShowAgreement(false);
                }}
            />
        </div>
    );
}
