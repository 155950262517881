import ja from "./ja.json";
import en from "./en.json";
import fr from "./fr.json";
import pt from "./pt.json"
import de from "./de.json"

export const resources = {
    "ja": {
        translation: ja
    },
    "en": {
        translation: en
    },
    "fr": {
        translation: fr
    },
    "pt": {
        translation: pt
    },
    "de": {
        translation: de
    }
}