import {
  Button,
  Dialog,
  DialogBody,
  DialogFooter,
  DialogHeader,
  Spinner,
} from "@material-tailwind/react";
import { ImgComparisonSlider } from "@img-comparison-slider/react";
import { useState } from "react";
import { useTranslation } from "react-i18next";

interface IProps {
  show: boolean;
  msg: string;
  title: string;
  onCancel: () => void;
}

export function FaceEditDemoShowDialog(
  props: IProps = {
    title: "Demo Show",
    msg: "",
    show: false,
    onCancel: () => {},
  }
) {
  const { show, msg, onCancel, title } = props;
  const {t} = useTranslation()
  return (
    <Dialog
      placeholder={""}
      open={show}
      handler={(e) => {
        onCancel();
      }}
      size={"xs"}
      className="bg-dark-bg"
    >
      <DialogHeader placeholder={""} className="text-white">
        {t('face_swap_comparison')}
      </DialogHeader>
      <DialogBody placeholder={""} className="text-white">
        <div className="relative">
          <ImgComparisonSlider hover={false} className="w-full outline-none" value={41}>
            <figure slot="first">
              <img
                className="object-contain h-102 w-full"
                width="100%"
                src="https://s3.us-east-2.amazonaws.com/www.deloris-ai.com/2721718179821_.pic.jpg"
              />
              <figcaption
                className="left-1 bg-white border border-slate-200 rounded-1xl text-black opacity-80 p-2
              absolute top-6 -translate-y-1/2 leading-100 text-center text-base"
              >
                {t('origin_video')}
              </figcaption>
            </figure>
            <figure slot="second">
              <img
                className="object-contain  h-102 w-full"
                width="100%"
                src="https://s3.us-east-2.amazonaws.com/www.deloris-ai.com/2731718179847_.pic.jpg"
              />
              <figcaption
                className="right-1 bg-white border border-slate-200 rounded-1xl text-black opacity-80 p-2
              absolute top-6 -translate-y-1/2 leading-100 text-center text-base"
              >
                {t('swapped_video')}
              </figcaption>
            </figure>
          </ImgComparisonSlider>
        </div>
      </DialogBody>
      <DialogFooter placeholder={""}>
        <Button
          placeholder={""}
          variant="text"
          color="red"
          onClick={(e) => {
            e.stopPropagation();
            onCancel();
          }}
          className="mr-1 text-white"
        >
          <span className="text-lg">{t('ok')}</span>
        </Button>
      </DialogFooter>
    </Dialog>
  );
}
