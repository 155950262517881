import {useCreateStores} from "../../stores/CreateStores";
import {stat} from "fs";
import React from "react";
import {useUserStores} from "../../stores/UserStores";
import {useNavigate} from "react-router-dom";
import { useTranslation } from "react-i18next";

export function NumberSelector() {
    const {batchNumber, setBatch} = useCreateStores((state) => ({
        batchNumber: state.createEntity.batchNumber,
        setBatch: state.setBatch
    }))

    const {userInfo} = useUserStores((state) => ({
        userInfo: state.userEntity,
    }))

    const navigation = useNavigate()
    const {t} = useTranslation()
    return (<div
        className="select-none flex flex-row justify-between  w-full  h-500 border-gray border-2 rounded-[10px] h-[100px] mt-[15px]">

        <div className="flex flex-col   bg-dark-bg ml-[10px] mt-[10px]">
            <div className="text-white font-bold text-[20px]">{t('size_of_images')}</div>
        </div>
        <div className="flex flex-row">
            {[1, 4, 9].map((item) => {
                const selected = item === batchNumber
                return <div
                    onClick={() => {
                        if (item == 1) {
                            setBatch(item)
                        } else if (userInfo && userInfo.entitlement && userInfo.entitlement.member_level && userInfo.entitlement.member_level[0] > 0) {
                            setBatch(item)
                        } else {
                            navigation('/subscribe')
                        }
                    }}
                    className="relative flex flex-row items-center space-x-3 mr-[10px]">

                    <div
                        className={`cursor-pointer flex flex-row items-center bg-gray justify-center text-white text-[15px] w-[50px] h-[50px] border-${selected ? 'primary-color' : 'gray'} border-[2px] rounded-[10px]`}>
                        {item}
                    </div>
                    {(item > 1) && <div
                        className='absolute top-[12px]  bg-red-500 h-[20px] rounded-[12.5px] text-white text-[12px] font-bold pl-2 pr-2'
                    >Pro
                    </div>}
                </div>
            })}
        </div>
    </div>)
}