import {Downloading} from "../common/Downloading";
import {LazyLoadImage} from "react-lazy-load-image-component";
import React from "react";

interface IProps {
    img_url?:string
    onImageLoaded:()=>void
}
export default function AiImage(props:IProps) {

    const {img_url,onImageLoaded} = props
    return (<div className="relative w-full h-full no-scrollbar scrollbar-hide overflow-y-scroll">
            <div className=" bg-black w-full h-full ">
                <Downloading/>
                <LazyLoadImage
                    className="w-full h-full rounded-[0px] lg:rounded-[20px]  h-full object-cover"
                    onLoad={() => {
                        onImageLoaded()
                    }}
                    effect="blur"
                    wrapperProps={{
                        style: {transitionDelay: "200ms"},
                    }}
                    width={'100%'}
                    height={'100%'}
                    src={img_url} // use normal <img> attributes as props
                />
            </div>
        </div>
    )
}