export const ConfigData = {
    promptTags: [{name: 'cute girl', index: 0}, {
        name: 'brown hair girl',
        index: 1
    }, {name: 'office asia lady', index: 2},
        {name: 'smile girl on beach', index: 3}],
    modelStyle: [
        {
            index: 0,
            name: 'Real Girl',
            model_name: 'edge-of-realism',
            image: 'https://s3.us-east-2.amazonaws.com/www.deloris-ai.com/example/20240212-210356.jpg'
        },
        {
            index: 1,
            name: 'Anime Girl',
            model_name: 'love',
            image: 'https://s3.us-east-2.amazonaws.com/www.deloris-ai.com/example/20240212-211658.jpg'
        },
    ],
    model: [
        {
            index: 1,
            name: 'Anime Girl',
            model_name: 'love',
            image: 'https://picso-files.faceplay.me/cdn-cgi/image/width=128/https://d1owhbh94qrb43.cloudfront.net/US/20230315/MnNIMybLzD/a63534ed-a901-4e8b-baeb-3e448597ccf6.jpeg'
        },
        {
            index: 0,
            name: 'Real Girl',
            model_name: 'edge-of-realism',
            image: 'https://picso-files.faceplay.me/cdn-cgi/image/width=128/https://d1owhbh94qrb43.cloudfront.net/US/20230315/DHUczzvfNh/0f38af2d-60b1-4b8b-aad5-59fadd3e5e4f.jpeg'
        },
    ],

    tabs: [
        {
            name: 'Action', tags: [
                {name: 'standing', index: 0},
                {name: 'sitting', index: 1}, {
                    name: 'kneeling',
                    index: 2
                }, {name: 'raising arm', index: 3},
                {name: 'lying', index: 4},
                {name: 'crossed arm', index: 5},
                {name: 'grabbing', index: 6},
                {name: 'lifting oneself', index: 7},
                {name: 'wide stance', index: 8},
                {name: 'squatting', index: 9},
            ],
        },
        {
            name: 'Body', tags: [
                {name: 'bare shoulders', index: 0},
                {name: 'collarbone', index: 1}, {
                    name: 'back',
                    index: 2
                }, {name: 'midriff', index: 3},
                {name: 'navel', index: 4},
                {name: 'buttocks', index: 5},
                {name: 'thighs', index: 6},
                {name: 'legs', index: 7},
                {name: 'muscular', index: 8},
            ],
        },
        {
            name: 'Clothing', tags: [
                {name: 'fishnet legwear', index: 0},
                {name: 'garter belt', index: 1}, {
                    name: 'lace trimmed legwear',
                    index: 2
                }, {name: 'high heels', index: 3},
                {name: 'undergarments', index: 4},
                {name: 'lace trimmed top', index: 5},
                {name: 'bow top', index: 6},
                {name: 'striped top', index: 7},
                {name: 'plaid top', index: 8},
                {name: 'strap', index: 9},
                {name: 'strapless top', index: 10},
                {name: 'top lift', index: 11},
                {name: 'skirt', index: 12},
                {name: 'miniskirt', index: 13},
                {name: 'underpants', index: 14},
                {name: 'shorts', index: 15},
                {name: 'denim', index: 16},
                {name: 'swimsuit', index: 17},
                {name: 'tank top', index: 18},
                {name: 'swimwear', index: 19},
                {name: 'pencil skirt', index: 20},
                {name: 'leotard', index: 21},
                {name: 'animal print', index: 22},
                {name: 'tradition clothes', index: 23},
            ],
        },
        {
            name: 'Hair', tags: [
                {name: 'brown hair', index: 0},
                {name: 'blonde hair', index: 1}, {
                    name: 'black hair',
                    index: 2
                }, {name: 'red hair', index: 3},
                {name: 'orange hair', index: 4},
                {name: 'white hair', index: 5},
                {name: 'multicolored hair', index: 6},
                {name: 'long hair', index: 7},
                {name: 'short hair', index: 8},
                {name: 'curly hair', index: 9},
                {name: 'hair bun', index: 10},
                {name: 'bangs', index: 11},
                {name: 'twintails', index: 12},
                {name: 'braid', index: 13},
                {name: 'twin braids', index: 14},
            ],
        },
        {
            name: 'Face', tags: [
                {name: 'brown eyes', index: 1},
                {name: 'blue eyes', index: 2},
                {name: 'closed eyes', index: 3},
                {name: 'green eyes', index: 4},
                {name: 'black eyes', index: 5},
                {name: 'eyeshadow', index: 6},
                {name: 'smile', index: 7},
                {name: 'grin', index: 8},
                {name: 'freckles', index: 9},

            ],
        },
        {
            name: 'Scene', tags: [
                {name: 'on grass', index: 0},
                {name: 'tree', index: 1}, {
                    name: 'plant',
                    index: 2
                }, {name: 'pool', index: 3},
                {name: 'lake', index: 4},
                {name: 'beach', index: 5},
                {name: 'sand', index: 6},
                {name: 'sky', index: 7},
                {name: 'curtains', index: 8},
                {name: 'window', index: 9},
                {name: 'bed', index: 9},
                {name: 'showering', index: 10},
            ],
        },
        {
            name: 'Accessories', tags: [
                {name: 'necklace', index: 0},
                {name: 'choker', index: 1}, {
                    name: 'hair ornament',
                    index: 2
                }, {name: 'bracelet', index: 3},
                {name: 'gloves', index: 4},
                {name: 'cuffs', index: 5},
                {name: 'tattoo', index: 6},
                {name: 'ring', index: 7},
                {name: 'hat', index: 8},
                {name: 'watch', index: 9},
                {name: 'sunglasses', index: 10},
                {name: 'balloon', index: 11},
            ],
        },
    ]

}
