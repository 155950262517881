import React from "react";
import { useTranslation } from "react-i18next";
import {useNavigate} from "react-router-dom";

export function TeamsService(){
    const navigate = useNavigate()
    const gotoTeamsOfService = () => {
        navigate('/tos')
    }

    const gotoPrivacy = () => {
        navigate('/pp')
    }
    const {t} = useTranslation()
    return(<div className='flex mt-[20px] mb-[20px] text-gray-d  text-[14px]  flex-row  justify-center items-center'>
        <div
            onClick={gotoTeamsOfService}
            className='select-none flex hover:cursor-pointer hover:text-white flex-row ml-2 mr-2'>{t('teams_of_service')}
        </div>
        <div> & </div>
        <div
            onClick={gotoPrivacy}
            className='select-none flex hover:cursor-pointer hover:text-white flex-row ml-2 '>{t('privacy_policy')}
        </div>
    </div>)
}