import {ProjectConfig} from "../create/CreateApi";
import {ResponseWrap} from "../../service/Api";

export interface TaskQueryRequest {
    project_id: string
}

export interface TaskDeleteRequest {
    id: string
}

export interface TaskDeleteResponse {
    project_id: string
}


export interface TaskQueryResponse extends ResponseWrap<ProjectEntity> {
    data: ProjectEntity
}

export interface TasksQueryRequest {
    page:number
    size:number
    project_type:string | undefined
}

export interface ProjectListResp{
    //总数据条数
    total:number
    //总页数
    number_page:number
    //当前页
    page:number,
    //页码大小
    size:number,
    has_next:boolean,
    data: ProjectEntity[]
}

export interface TasksQueryResponse extends ResponseWrap<ProjectListResp> {
    data: ProjectListResp
}

export interface TaskDeleteResponse extends ResponseWrap<ProjectEntity> {
    data: ProjectEntity
}


type ProjectStatus = [
    statusCode: number,
    statusText: string
];



export interface Prompt {
    ch: string,
    en: string
}

export interface Segment {
    index: number,
    text: string,
    image_url: string
    prompt: Prompt
    nsfw_result: boolean,
    origin_video_url: string | undefined,
    gen_video_url: string | undefined,
    tts_duration: number | undefined,
    video_file_size: number | undefined,
    video_duration: number | undefined,
    estimated_time: number | undefined,
    advance_payment: number |undefined
    start_time: string | undefined,
    tag: string | undefined,

}

export interface ProjectEntity {

    id: string
    cover_img:string
    project_status: ProjectStatus
    project_config: ProjectConfig,
    project_segments: Segment[],
    project_novel_text: string

}

export enum ProjectStatusEnum {
    TextEntered=1,
    TextSegmented=2,
    PromptGenerating = 3,
    PromptGenerated=4,
    ImageGenerating=5,
    TTSGenerated=6,
    ImageGenerated=7,
    Generated=8,
    SwapGenerating=9,
    SwapGenerateFailed=10,
    Failed = 11,
    VideoAnalysing=12,
    VideoAnalysed=13,
    FileNotSupport = 3001,
    FileOverSize = 3002,
    VideoNotContainFaces = 3003,
}

