import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleXmark, faEnvelope } from "@fortawesome/free-solid-svg-icons";
import {
  faInstagram,
  faSquareInstagram,
  faTiktok,
  faFacebookF,
  faTwitter,
  faYoutube,
  faDiscord,
} from "@fortawesome/free-brands-svg-icons";
import React from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

export function Footer() {
  const navigator = useNavigate();
  const gotoYoutube = () => {
    window.open("https://youtube.com/");
  };

  const gotoIns = () => {
    window.open("https://youtube.com/");
  };
  const gotoTT = () => {
    window.open("https://www.tiktok.com/");
  };
  const gotoFacebook = () => {
    window.open("https://www.facebook.com/");
  };
  const gotoTwitter = () => {
    window.open("https://twitter.com/deloris_ai");
  };
  const gotoMail = () => {
    window.open(
      "mailto:deloris@deloris-ai.com?subject=Feedback&body=Body%20Content"
    );
  };

  const gotoDiscord = () => {
    window.open("https://discord.com");
  };

  const gotoAffiliate = () => {
    window.open(
      "https://docs.google.com/forms/d/1PIL8dfPHkKX0FV0PzRttcpgIWUt0PYB0pvscTM9t9iE/edit"
    );
  };
  const gotoTeamsOfService = () => {
    navigator("/tos");
  };

  const gotoPrivacy = () => {
    navigator("/pp");
  };
  const { t } = useTranslation();
  return (
    <div className="w-full justify-end">
      <div className="flex  text-gray-d flex-row justify-center lg:justify-between items-start bg-dark-bg w-full h-[60px] lg:pl-[5%] lg:pr-[5%] pt-[10px] lg:pt-[0px]">
        <div className="hidden lg:flex  text-[14px]  flex flex-row  items-center">
          <div className=" hover:text-white ">{t("deloris_ai_version")}</div>
          <div className="ml-10  hover:text-white ">{t("community")}</div>
          <div className="flex flex-row">
            {/*<div*/}
            {/*    onClick={gotoIns}*/}
            {/*    className='bg-gray-a hover:cursor-pointer hover:bg-icon p-1 w-[30px] h-[30px] flex justify-center items-center rounded-[15px] ml-2'>*/}
            {/*    <FontAwesomeIcon icon={faInstagram} size={"lg"} color={'white'}/>*/}
            {/*</div>*/}

            {/*<div*/}
            {/*    onClick={gotoTT}*/}
            {/*    className='bg-gray-a hover:cursor-pointer hover:bg-icon p-1 w-[30px] h-[30px] flex justify-center items-center rounded-[15px] ml-2'>*/}
            {/*    <FontAwesomeIcon icon={faTiktok} size={"lg"} color={'white'}/>*/}
            {/*</div>*/}

            {/*<div*/}
            {/*    onClick={gotoFacebook}*/}
            {/*    className='bg-gray-a hover:cursor-pointer hover:bg-icon p-1 w-[30px] h-[30px] flex justify-center items-center rounded-[15px] ml-2'>*/}
            {/*    <FontAwesomeIcon icon={faFacebookF} size={"lg"} color={'white'}/>*/}
            {/*</div>*/}
            {/*<div*/}
            {/*    onClick={gotoYoutube}*/}
            {/*    className='bg-gray-a hover:cursor-pointer hover:bg-icon p-1 w-[30px] h-[30px] flex justify-center items-center rounded-[15px] ml-2'>*/}
            {/*    <FontAwesomeIcon icon={faYoutube} size={"lg"} color={'white'}/>*/}
            {/*</div>*/}
            <div
              onClick={gotoTwitter}
              className="bg-gray-a hover:cursor-pointer hover:bg-icon p-1 w-[30px] h-[30px] flex justify-center items-center rounded-[15px] ml-2"
            >
              <FontAwesomeIcon icon={faTwitter} size={"lg"} color={"white"} />
            </div>
          </div>
        </div>
        <div className="flex text-gray-d  text-[14px]  flex-row  justify-center items-start">
          <div className="flex flex-row">{t("contact_us")}</div>
          <div
            onClick={gotoMail}
            className="bg-gray-a hover:cursor-pointer hover:bg-icon p-1 w-[30px] h-[30px] flex justify-center items-center rounded-[15px] ml-2"
          >
            <FontAwesomeIcon icon={faEnvelope} size={"lg"} color={"white"} />
          </div>

          <div
            onClick={gotoAffiliate}
            className="flex hover:cursor-pointer hover:text-white flex-row ml-2"
          ></div>
          <div className="flex flex-col items-start">
            <div>{t("affiliate")}</div>

            <div className='flex flex-row'>
            <img src="https://porntourist.com/static/images/brand/i.svg" alt=""/>
            <a href="https://porntourist.com/?ref=deloris-ai" className="ptourist" target="_blank">
              porntourist
            </a>
            </div>

            <a
              className="hover:cursor-pointer hover:text-white flex-row "
              href="https://thebestfetishsites.com"
              title="thebestfetishsites"
            >
              thebestfetishsites
            </a>
            <a
              className="hover:cursor-pointer hover:text-white flex-row "
              href="https://dokeyai.com/"
              title="Dokey AI Tools Directory"
            >
              DokeyAI
            </a>
            <a
              className="hover:cursor-pointer hover:text-white flex-row "
              href="https://thepornlinks.com/"
              title="Best Porn Sites"
              target="_blank"
            >
              ThePornLinks.com
            </a>
          </div>

          <div className="flex flex-col">
            <div
              onClick={gotoTeamsOfService}
              className="flex hover:cursor-pointer hover:text-white flex-row ml-2 "
            >
              {t("teams_of_service")}
            </div>
            <div
              onClick={gotoPrivacy}
              className="flex hover:cursor-pointer hover:text-white flex-row ml-2 "
            >
              {t("privacy_policy")}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
