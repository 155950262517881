import {ProductEntity} from "../model/subscribe/Product";
import {create} from "zustand";
import {apiService} from "../service/Api";
import {CreatePaymentRequest, CreatePaymentResponse, GetProductRequest, GetProductResponse} from "../api/user/UserApi";
import {SubscriptionChannel} from "../model/subscribe/SubscriptionChannel";
import toast from "react-hot-toast";
import {useUserStores} from "./UserStores";
import {UserEntity} from "../model/UserEntity";

type Action = {
    selectProduct: (product: ProductEntity) => void,
    createPayment: () => void,
    clearPaymentStatus: () => void,
    fetchProducts: (userEntity?:UserEntity) => void
    resetRefresh: () => void
}
type State = {
    allProducts: ProductEntity[],
    products: ProductEntity[],
    selectProductId: string,
    needRefreshProduct: boolean,
    paymentStatus: 'idle' | 'creating' | 'success' | 'failed',
    fetchStatus: 'idle' | 'fetching' | 'success' | 'failed'
    createResp: CreatePaymentResponse | undefined
}

const initStatus: State = {
    products:[],
    allProducts: [
        {id: 'prod_PJTPURvkiIj0Gy', name: '1 month', amount: 9.9, origin_amount: 9.9, discount_label: '50%', level: 1},
        {
            id: 'prod_PJTQVbHWp21jZt',
            name: '12 months',
            amount: 49.9,
            origin_amount: 249.99,
            discount_label: '50%',
            level: 1
        },

        {
            id: 'prod_PJTSRF6y6YqPvq',
            name: '100 credis',
            amount: 9.99,
            origin_amount: 10,
            level: 2
        },
        {
            id: 'prod_PJTSQ6zCMPhgDY',
            name: '500 credits',
            amount: 45.99,
            origin_amount: 49.99,
            discount_label: '50%',
            level: 2
        },
        {
            id: 'prod_PJTTLP2X9bGUVw',
            name: '1000 credits',
            amount: 89.9,
            origin_amount: 99.99,
            discount_label: '50%',
            level: 2
        },
        {
            id: 'prod_PJTTmDk5t3ch1Y',
            name: '3000 credits',
            amount: 239.9,
            origin_amount: 299.99,
            discount_label: '50%',
            level: 2
        },
        ],
    selectProductId: '1',
    paymentStatus: 'idle',
    fetchStatus: 'idle',
    createResp: undefined,
    needRefreshProduct: true
}


export const useSubscribeStores = create<Action & State>((set, get) => ({
    ...JSON.parse(JSON.stringify(initStatus)),
    selectProduct(product) {
        set(state => ({
            selectProductId: product.id
        }))
    },
    clearPaymentStatus() {
        set((state) => ({
            createResp: undefined,
            paymentStatus: 'idle'
        }))
    },
    createPayment() {
        const selectProductId = get().selectProductId
        const req: CreatePaymentRequest = {
            product_id: selectProductId,
            channel: SubscriptionChannel.Stripe
        }
        set((state) => ({
            paymentStatus: 'creating'
        }))
        apiService.CreatePayment(req).then((resp: CreatePaymentResponse) => {
            console.log(`createPaymentResponse:${resp}`)
            set((state) => ({
                createResp: resp,
                paymentStatus: 'success'
            }))
        }).catch((err) => {
            toast.error('Create Subscription Failed')
            set((state) => ({
                paymentStatus: 'idle'
            }))
        })
    },
    fetchProducts(userEntity:UserEntity) {
        const req: GetProductRequest = {}
        apiService.GetProducts(req).then((resp: GetProductResponse) => {
            if (resp && resp.code == 200) {
                const allProducts = resp.data
                const selectProductId = get().selectProductId
                let member = 0
                if (userEntity && userEntity.entitlement && userEntity.entitlement.member_level) {
                    member = userEntity.entitlement.member_level[0]
                }
                const products: ProductEntity[] = member <= 0 ? allProducts.filter(item => {
                    return item.level == 1
                }).slice(0,3) : allProducts.filter(item => {
                    return item.level == 2
                }).slice(0,4)
                const selectInProducts = products.some(item => item.id === selectProductId)
                set(state => ({
                    selectProductId: selectInProducts ? selectProductId : products[0].id,
                    products: products,
                    allProducts: allProducts,
                    needRefreshProduct: false
                }))
            }
        }).catch((err) => {

        })
    },
    resetRefresh() {
        set((state) => ({
            needRefreshProduct: true,
            paymentStatus:'idle'
        }))
    },
}))