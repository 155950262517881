import React from "react";
import Accordion from '@mui/material/Accordion';
import AccordionActions from '@mui/material/AccordionActions';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import {ItemBullet} from "./ItemBullet";
import { useTranslation } from "react-i18next";

export function FAQ() {
    const [expanded, setExpanded] = React.useState<string | false>(false);

    const handleChange =
        (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
            setExpanded(isExpanded ? panel : false);
        };
          const {t} = useTranslation()
    return (
        <div
            className=' flex flex-col justify-center items-center  w-full rounded-[20px] pb-[10px] pt-[10px] pb-[10%]  mt-[10px]'>
            <Accordion
                className='bg-red-500  bg-dark-bg w-full'
                expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
                <AccordionSummary
                    sx={{
                        overflow: 'hidden',
                        boxShadow: '0px',
                        color: 'red',
                        backgroundColor: "rgb(25,27,32)",
                    }}
                    className='bg-dark-bg '
                    aria-controls="panel1bh-content"
                    id="panel1bh-header"
                >

                    <div className='text-white select-none w-full flex justify-start items-center'>
                        <ItemBullet></ItemBullet>
                                  {t('what_payment_services_do_you_support')}
                    </div>

                </AccordionSummary>
                <AccordionDetails
                    sx={{
                        backgroundColor: "rgb(25,27,32)"
                    }}
                >
                    <div className='text-white flex justify-start'>
                                  {t('we_accept_all_major_credit_cards')}
                    </div>
                </AccordionDetails>
            </Accordion>


            <Accordion
                sx={{}}
                className='bg-red-500  bg-dark-bg w-full'
                expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
                <AccordionSummary
                    sx={{
                        overflow: 'hidden',
                        boxShadow: '0px',
                        backgroundColor: "rgb(25,27,32)"
                    }}
                    className='bg-dark-bg '
                    aria-controls="panel2bh-content"
                    id="panel2bh-header"
                >
                    <div className='text-white   w-full flex justify-start items-center'>
                        <ItemBullet></ItemBullet>
                                  {t('is_this_a_secure_site_for_purchases')}
                    </div>

                </AccordionSummary>
                <AccordionDetails
                    sx={{
                        borderTop: '1px solid gary',
                        backgroundColor: "rgb(25,27,32)"
                    }}
                >
                    <div className='text-left text-white w-full flex justify-start'>
                                  {t('we_work_with_stripe_which_guarantees_your_safety_and_security')}
                    </div>
                </AccordionDetails>
            </Accordion>


            <Accordion
                sx={{}}
                className='bg-red-500  bg-dark-bg w-full'
                expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
                <AccordionSummary
                    sx={{
                        overflow: 'hidden',
                        boxShadow: '0px',
                        backgroundColor: "rgb(25,27,32)"
                    }}
                    className='bg-dark-bg '
                    aria-controls="panel3bh-content"
                    id="panel3bh-header"
                >
                    <div className='text-left text-white w-full flex justify-start items-center'>
                        <ItemBullet></ItemBullet>
                                  {t('can_i_cancel_my_subscription')}
                    </div>

                </AccordionSummary>
                <AccordionDetails
                    sx={{
                        borderTop: '1px solid gary',
                        backgroundColor: "rgb(25,27,32)"
                    }}
                >
                    <div className='text-left text-white flex justify-start'>
                                  {t('monthly_subscriptions_are_billed_month_to_month')}
                    </div>
                </AccordionDetails>
            </Accordion>

            <Accordion
                sx={{}}
                className='bg-red-500  bg-dark-bg w-full'
                expanded={expanded === 'panel4'} onChange={handleChange('panel4')}>
                <AccordionSummary
                    sx={{
                        overflow: 'hidden',
                        boxShadow: '0px',
                        backgroundColor: "rgb(25,27,32)"
                    }}
                    className='bg-dark-bg '
                    aria-controls="panel4bh-content"
                    id="panel4bh-header"
                >
                    <div className='text-white   w-full flex justify-start items-center'>
                        <ItemBullet></ItemBullet>
                                  {t('can_i_update_my_card_details')}
                    </div>

                </AccordionSummary>
                <AccordionDetails
                    sx={{
                        borderTop: '1px solid gary',
                        backgroundColor: "rgb(25,27,32)"
                    }}
                >
                    <div className='text-left text-white flex justify-start'>
                                  {t('go_to_the_billing_section_of_your_account_and_update_your_payment_information')}
                    </div>
                </AccordionDetails>
            </Accordion>

        </div>
    )
}