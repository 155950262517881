import {useSubscribeStores} from "../../stores/SubscribeStores";
import React, {useEffect} from "react";
import {Badge} from "@material-tailwind/react";
import {useUserStores} from "../../stores/UserStores";
import {ProductEntity} from "../../model/subscribe/Product";
import {useNavigate} from "react-router-dom";

export function ProductList() {
    const {products, selectProduct, selectProductId} = useSubscribeStores((state) => ({
        products: state.products,
        selectProduct: state.selectProduct,
        selectProductId: state.selectProductId,
    }))

    return (<div className=' mt-0 w-full pl-[5%] pr-[5%] lg:w-[50%] lg:pl-[0%] lg:pr-[0%]'>
        {
            products.map((item) => {
                const selected = item.id === selectProductId
                return <div
                    onClick={() => {
                        selectProduct(item)
                    }}

                    className={`relative flex mt-5 flex-row justify-between w-full border-${selected ? 'primary-color' : 'gray-d'}  ${selected ? 'border-[2px]' : 'border-[2px]'} rounded-xl p-2 pt-3 pb-3`}>
                    <div className='flex flex-row '>
                        <div className='text-white  font-bold text-[18px] pl-3'>{item.name.split(' ')[0]}</div>
                        <div className='text-white text-[16px]  ml-aut pl-1 flex flex-col justify-end'>
                            <div>
                                {item.name.split(' ')[1]}
                            </div>
                        </div>
                    </div>
                    <div className='flex flex-row items-center pr-3'>
                        <div className='text-white text-[15px] line-through'>${item.origin_amount}</div>
                        <div className='text-white font-bold text-[18px] ml-1'>${item.amount}</div>
                    </div>
                    <div className='absolute top-[-10px] left-[10px]'>

                    </div>
                    {item.discount_label && <div
                        className='absolute top-[-11px] left-4 bg-red-500 h-[20px] rounded-[12.5px] text-white text-[12px] font-bold pl-2 pr-2'
                    >{item.discount_label}
                    </div>}
                </div>
            })
        }
    </div>)
}