import {Spinner} from "@material-tailwind/react";
import {useNavigate} from "react-router-dom";
import exp from "constants";
import { useTranslation } from "react-i18next";

export interface IProps {
    onRetry: () => void
    errorMsg:string
}

export function PageError(props: IProps) {
    const {onRetry,errorMsg} = props
    const {t} = useTranslation()
    return (<div className="absolute flex flex-col inset-0 flex items-center justify-center">
        <div className="text-white text-[22px] mt-[10px]">{errorMsg}</div>
        <div
            onClick={onRetry}
            className="cursor-pointer select-none pl-[10px]  pr-[10px] bg-gradient-to-r from-blue-500 to-pink-500 flex flex-row justify-center items-center text-[22px] bg-gray  border-gray rounded-[10px] h-[50px] mt-[20px]">
            <div className="text-white text-[18px]">{t('retry')}</div>
        </div>
    </div>)
}