import React from "react";
import { Button, Spinner, Typography } from "@material-tailwind/react";
import {useNavigate, useParams} from "react-router-dom";
import { Footer } from "../../components/common/Footer";
import { useTranslation } from "react-i18next";
import i18n from "../../react-i18next/i18n";

export function TeamsService() {
  const {lng} = useParams();
  i18n.changeLanguage(lng);
  const { t } = useTranslation();
  return (
    <div className="flex-col w-full h-screen bg-dark-bg ">
      <div className="flex-col h-full text-white bg-dark-bg">
        <div className="text-left bg-dark-purple h-full m-10  rounded-[20px] p-10 flex flex-col justify-start items-start">
          <h1>{t('deloris_privacy_policy')}</h1>{" "}
          <p>{t('this_privacy_policy_describes')}
            
          </p>
          <h2>{t('personal_information_we_collect')}</h2>{" "}
          <p>{t('when_you_visit_the_site')}
           
          </p>{" "}
          <p>{t('we_collect_device_information_using_the_following_technologies')}</p>
          <ul>
            <li>{t('cookies_are_data_files_that_are_placed')}
              
            </li>
            <li>{t('log_files_track_actions_occurring_on_the_site')}
              
            </li>
            <li>{t('web_beacons_tags_and_pixels')}
             
            </li>
          </ul>
          <p>{t('additionally_when_you_make_a_purchase')}
            
          </p>{" "}
          <p>{t('when_we_talk_about_personal_information')}
            
          </p>{" "}
          <h2>{t('how_do_we_use_your_personal_information')}</h2>{" "}
          <p>{t('we_use_the_order_information_that_we_collect_generally')}
            
          </p>
          <ul>
            <li>{t('communicate_with_you')}</li>
            <li>{t('screen_our_orders_for_potential_risk_or_fraud_and')}</li>
            <li>{t('when_in_line_with_the_preferences_you_have_shared_with_us')}
              
            </li>
          </ul>
          <p>{t('we_use_the_device_information_that_we_collect')}
            
          </p>{" "}
          <h2>{t('sharing_you_personal_information')}</h2>{" "}
          <p>{t('we_share_your_personal_information_with_third_parties')}
            
          </p>
          <h2>{t('behavioral_advertising')}</h2>{" "}
          <p>{t('as_described_above_we_use_your_personal_information')}
            
          </p>{" "}
          <p>{t('you_can_opt_out_of_some')}
            
          </p>{" "}
          <h2>{t('your_rights')}</h2>{" "}
          <p>{t('if_you_are_a_european_resident')}
            
          </p>{" "}
          <p>{t('additionally_if_you_are_a_european_resident')}
            
          </p>{" "}
          <h2>{t('data_retention')}</h2>{" "}
          <p>{t('when_you_place_an_order_through_the_site')}
            
          </p>{" "}
          <h2>{t('changes')}</h2>{" "}
          <p>{t('we_may_update_this_privacy_policy')}
            
          </p>{" "}
          <h2>{t('contact_us')}</h2>{" "}
          <p>{t('for_more_information_about_our_privacy_practices')}
            
          </p>{t('deloris_re_privacy_compliance_officer')}
          
        </div>
      </div>
      <Footer></Footer>
    </div>
  );
}
