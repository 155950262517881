import { useTranslation } from "react-i18next"

export function SizeSelector() {
    const {t} = useTranslation()
    return (<div
        className="select-none flex flex-row justify-between  w-full  h-500 border-gray border-2 rounded-[10px] h-[100px] mt-[15px]">

        <div className="flex flex-col   bg-dark-bg ml-[10px] mt-[10px]">
            <div className="text-white font-bold text-[20px]"> {t('size_of_images')}</div>
        </div>
        <div className="flex flex-row items-center  space-x-3 mr-[10px]">
                <div className="cursor-pointer flex flex-row items-center bg-gray justify-center text-white text-[15px] w-[40px] h-[60px] border-primary-color border-[2px] rounded-[10px]">
                        3:2
                </div>
        </div>
    </div>)
}