import React, {useEffect, useState} from 'react';
import {Link, useMatch, useNavigate} from "react-router-dom";
import {NavLink} from "react-router-dom";
import {useUserStores} from "../stores/UserStores";
import {Avatar, Button} from "@material-tailwind/react";
import {ProfileMenu} from "./nav/ProfileMenu";
import {Box, Divider, List, ListItem, ListItemButton, ListItemIcon, ListItemText, SwipeableDrawer} from "@mui/material";
import {InboxIcon} from "@heroicons/react/20/solid";
import {Drawer} from "./nav/Drawer";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faBars} from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";
import {LanguagesMenu} from "./nav/LanguagesMenu";


type Anchor = 'top' | 'left' | 'bottom' | 'right';
const Navbar = () => {
    const {t} = useTranslation();
    const {userInfo, initUserInfo} = useUserStores((state) => ({
        userInfo: state.userEntity,
        initUserInfo: state.initUserInfo
    }))
    useEffect(() => {
        if (!userInfo) {
            initUserInfo()
        }

    }, [userInfo])
    const navigate = useNavigate()


    function useRouteMatch(patterns: string) {
        const result = !!useMatch({path: patterns});
        return result
    }


    const [open, setOpen] = useState(false)

    return (
        <div className="bg-dark-bg w-full min-h-[60px] h-[60px] flex flex-row select-none">
            <nav className=" flex flex-row justify-between  w-full ">
                <div className="flex flex-col justify-center text-white ml-10">
                    <NavLink className="text-[24px] font-bold " to="/home">Deloris</NavLink>
                </div>

                <div className='block lg:hidden flex flex-row'>
                    {/*<LanguagesMenu></LanguagesMenu>*/}
                    <React.Fragment key={'right'}>
                        <Button placeholder='' onClick={() => {
                            setOpen(true)
                        }
                        }>
                            <FontAwesomeIcon size={"2xl"} icon={faBars}></FontAwesomeIcon>
                        </Button>
                        <SwipeableDrawer
                            anchor={'right'}
                            open={open}
                            onClose={() => {
                                setOpen(false)
                            }
                            }
                            onOpen={() => {
                                setOpen(true)
                            }
                            }
                        >
                            <Drawer onClose={()=>{setOpen(false)}}></Drawer>
                        </SwipeableDrawer>
                    </React.Fragment>
                </div>

                <div className='flex hidden lg:block h-full items-center justify-center'>
                    <div className="flex flex-row mr-20 justify-center items-center  h-[60px]">
                        <NavLink
                            className={`flex flex-col justify-center ml-10 text-[18px] font-bold  text-primary-color ${useRouteMatch('/create') ? 'text-primary-color' : 'text-white'}`}
                            to="create">{t('nav_create')}</NavLink>
                        <NavLink
                            className={`flex flex-col justify-center ml-10 text-[18px] text-primary-color font-bold  ${useRouteMatch('/face-swap') ? 'text-primary-color' : 'text-white'}`}
                            to="face-swap">
                            <div className={"relative"}>
                                <div
                                    className='absolute top-[-8px] right-[-25px]  bg-red-500 h-[15px] rounded-[12.5px] text-white text-[10px] font-bold pl-2 pr-2'
                                >{t('new')}
                                </div>
                            </div>
                            <div className={` font-bold  ${useRouteMatch('/face-swap') ? 'text-primary-color' : 'text-white'}`}>{t('face_swap')}</div>

                        </NavLink>

                        <NavLink
                            className={`flex flex-col justify-center ml-10 text-[18px] text-primary-color font-bold  ${useRouteMatch('/my-creations') ? 'text-primary-color' : 'text-white'}`}
                            to="my-creations">
                            {t('nav_my_creation')}
                        </NavLink>
                        <NavLink
                            className={`flex flex-col justify-center ml-10 text-[18px] text-primary-color font-bold  ${useRouteMatch('/subscribe') ? 'text-primary-color' : 'text-white'}`}
                            to="subscribe">{t('nav_pricing')}</NavLink>

                        {!userInfo && <div className='flex flex-row'>
                            <Button
                                onClick={() => {
                                    navigate('login?mode=register')
                                }}
                                className="text-[18px] font-bold flex flex-row justify-center items-center normal-case bg-gradient-to-r from-blue-500 to-pink-500  w-[90px] pl-[10px] pr-[10px] h-[40px] ml-[20px]"
                                placeholder={''}
                            >{t('login_in')}</Button>

                            <Button
                                onClick={() => {
                                    navigate('login?mode=login')
                                }}
                                className="text-[18px] font-bold flex flex-row justify-center items-center normal-case bg-gray  w-[90px] pl-[10px] pr-[10px] h-[40px] ml-[20px]"
                                placeholder={''}
                            >{t('sign_up')}</Button>
                        </div>}
                        {<LanguagesMenu></LanguagesMenu>}
                        {userInfo && <ProfileMenu></ProfileMenu>}
                    </div>
                </div>
            </nav>

        </div>
    );
}

export default Navbar;