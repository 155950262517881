import {
  Button,
  Dialog,
  DialogBody,
  DialogFooter,
  DialogHeader,
  Spinner,
} from "@material-tailwind/react";
import { use } from "i18next";
import React, { useTransition } from "react";
import { useTranslation } from "react-i18next";

interface IProps {
  show: boolean;
  msg: string;
  title: string;
  onCancel: () => void;
}

export function HomeHintDialog(props: IProps) {
  const { show, msg, onCancel, title } = props;
  const { t } = useTranslation();
  return (
    <Dialog
      placeholder={""}
      open={show}
      handler={(e) => {}}
      className="bg-dark-bg"
    >
      <DialogHeader placeholder={""} className="text-white">
        {t("warning")}
      </DialogHeader>
      <DialogBody
        placeholder={""}
        className="text-white flex flex-col place-items-center"
      >
        <div className="text-neutral-400  text-center">
          {t("home_dialog_hint_1")}
        </div>

        <div className="text-red-600 text-lg text-center">
          {t("home_dialog_hint_2")}
        </div>
        <div className="text-neutral-400  text-center">
          {t("home_dialog_hint_3")}
        </div>
        <div className="m-4">
          <Button
            className="mr-4"
            size="md"
            color="purple"
            placeholder={undefined}
            onClick={() => {
              onCancel();
            }}
          >
            {t("yes")}
          </Button>
          <Button
            size="md"
            color="purple"
            placeholder={undefined}
            onClick={() => {
              window.close();
            }}
          >
            {t("no")}
          </Button>
        </div>
        <div className="text-neutral-400  text-center">
          {t("home_dialog_hint_4")}
        </div>
      </DialogBody>
      <DialogFooter placeholder={""}>
        <div></div>
      </DialogFooter>
    </Dialog>
  );
}
