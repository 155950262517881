
export type ApiErrorOption = {
    httpStatus: string;
    /**
     * 业务 code
     */
    code?: string;
    message?: string;
    logId?: string;
    requestConfig?: Record<string, unknown>;
    response?: Record<string, unknown>;
    /**
     * 错误类型，用于细化监控
     * @default ErrorType.ApiError
     */
    errorType?: string;
};

export class ApiError extends Error {
    errorOption: ApiErrorOption;

    constructor(option: ApiErrorOption) {
        super(
            `httpStatus=${option.httpStatus}, code=${option.code}, message=${option.message}, logId=${option.logId}`,
        );
        this.name = 'ApiError';
        this.errorOption = option;
    }
}