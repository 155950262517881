import React from "react";
import {LazyLoadImage} from "react-lazy-load-image-component";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCircleExclamation, faCircleXmark, faTrashCan} from "@fortawesome/free-solid-svg-icons";
import {ProjectEntity, Segment} from "../../api/task/TaskApi";
import {TaskEntity} from "../../model/task/TaskEntity";
import {NSFW} from "../task/NSFW";
import ItemNSFW from "./ItemNSFW";

export interface IProps {
    project: ProjectEntity
    onDelete: (taskId: string) => void,
    onDetail: (tak: TaskEntity) => void
}

export function CreationItem(props: IProps) {
    const {project, onDetail, onDelete} = props
    const segment = project.project_segments[0]


    const imageGenerated = () => {
        if (project.project_status[1] === 'image_generated') {
            if (segment && segment.image_url !== '' && segment.image_url !== undefined) {
                return true
            }
            return false
        }
        return false
    }
    const gotoDetail=()=>{
        const status = project.project_status[1]
        const task:TaskEntity= {
            task_id:project.id,
            prompt:project.project_segments[0].text,
            process:100,
            img_url:project.project_segments[0].image_url,
            nsfw_content:false,
            status:status == 'image_generated' ?'GENERATED' : 'GENERATING',
            projectEntity:project
        }
        onDetail(task)
    }
    const imgUrl = ()=>{
        if(project && project.cover_img && project.cover_img.length >0){
            return project.cover_img
        } else {
            return segment.image_url
        }
    }
    const containNSFW = ()=>{
        if(project && project.project_segments && project.project_segments.length >0){
            return project.project_segments.some((segment:Segment)=>{
                if(segment.nsfw_result){
                    return true
                }
            })
        }
        return false
    }

    const getContent = ()=>{
        if(containNSFW()){
            return(<ItemNSFW></ItemNSFW>)
        } else {
            return (
            <LazyLoadImage
                className="w-full h-full rounded-[20px]"
                onLoad={() => {
                    // setImageLoad(true)
                }}
                effect="blur"
                wrapperProps={{
                    style: {transitionDelay: "200ms"},
                }}
                width={'100%'}
                height={'100%'}
                src={imgUrl()}// use normal <img> attributes as props
            />)
        }
    }


    return (<div
        onClick={() => {
        gotoDetail()
    }} className="group w-full h-full cursor-pointer select-none">
        {segment &&
            <div
                className="group w-full h-full relative cursor-pointer flex justify-center items-center">
                {imageGenerated() &&
                    getContent()
                }
                {!imageGenerated() &&
                    <div
                        className="relative w-full h-full rounded-[20px] text-white h-full bg-red flex flex-row justify-center items-center bg-gray">

                        <img
                            src={require('../../../src/assets/login_bg.webp')} className='w-full h-full blur-sm rounded-[20px] '>
                        </img>
                        <div className='text-[18px] text-transparent text-white absolute font-bold inset-0 flex flex-col items-center justify-center '>Generating</div>
                    </div>
                }
                <div
                    className="w-full  h-[150px] rounded-bl-[20px] rounded-br-[20px] flex flex-column items-end justify-center absolute hidden group-hover:text-white bottom-[0px]  group-hover:flex group-hover:bg-gradient-to-b from-transparent to-black pb-[20px] ">
                    <div className="text-[12px] lg:text-[18px] line-clamp-3 lg:line-clamp-6 overflow-hidden whitespace-normal text-ellipsis max-w-[95%]">{segment.text ? segment.text : ''}</div>
                </div>

                <div
                    onClick={(e) => {
                        e.stopPropagation()
                        onDelete(project.id)
                    }}

                    className="hidden group-hover:block cursor-pointer   flex justify-center rounded-[15px] items-center absolute right-[10px] top-[10px] w-[30px] h-[30px] primary-color text-white text-[20px]">
                    {/* eslint-disable-next-line react/jsx-no-undef */}
                    <FontAwesomeIcon size={"xl"} color={'red'} icon={faCircleXmark}></FontAwesomeIcon>
                </div>
            </div>}
    </div>)
}

