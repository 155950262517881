import React, {useEffect} from "react";
import {Button, Spinner} from "@material-tailwind/react";
import {useSubscribeStores} from "../../stores/SubscribeStores";
import {ProductList} from "../../components/subscribe/ProductList";
import {ProBenefitInfo} from "../../components/subscribe/ProBenefitInfo";
import {useUserStores} from "../../stores/UserStores";
import {useNavigate, useParams} from "react-router-dom";
import {FAQ} from "../../components/subscribe/FAQ";
import {TeamsService} from "../../components/subscribe/TeamsService";
import {analytics, INVITE_CODE_KEY, SOURCE_ID_KEY} from "../../App";
import { getAnalytics, logEvent } from "firebase/analytics";
import {
    EVENT_ACTION_CREATE_PAYMENT,
    EVENT_PAGE_SUBSCRIBE,
} from "../../util/Event";
import { useTranslation } from "react-i18next";
import i18n from "../../react-i18next/i18n";

export function Subscribe() {
    const {lng} = useParams();
    i18n.changeLanguage(lng);
    const {t} = useTranslation()
    const {products, paymentStatus, createPayment, createResp, clearPaymentStatus,fetchProducts,resetRefresh,needRefresh} = useSubscribeStores((state) => ({
        products: state.products,
        paymentStatus: state.paymentStatus,
        createPayment: state.createPayment,
        createResp: state.createResp,
        clearPaymentStatus: state.clearPaymentStatus,
        fetchProducts:state.fetchProducts,
        needRefresh:state.needRefreshProduct,
        resetRefresh:state.resetRefresh

    }))
    const navigate = useNavigate()
    const {fetchUserInfo,userEntity} = useUserStores(state => ({userEntity:state.userEntity,fetchUserInfo:state.fetchUserInfo}))

    useEffect(() => {
        if (paymentStatus == 'success') {
            if(createResp && createResp?.data && createResp.data.stripe_params && createResp.data.stripe_params.checkout_url) {
                window.open(createResp?.data.stripe_params.checkout_url)
            }
            // clearPaymentStatus()
        }
    }, [paymentStatus])

    useEffect(()=>{
        if(needRefresh ) {
            if(userEntity) {
                fetchUserInfo()
            }
            fetchProducts(userEntity)
        }
    },[needRefresh])
    useEffect(()=>{
        const sourceId = localStorage.getItem(SOURCE_ID_KEY)
        logEvent(analytics, EVENT_PAGE_SUBSCRIBE, {
            page_path: EVENT_PAGE_SUBSCRIBE,
            sourceId:sourceId
        });
        return handleUnmount
    },[])

    const handleUnmount = () => {
        resetRefresh()
        console.log('handleUnmount')
    }

    const openPay = () => {
        if(userEntity) {
            logEvent(analytics, EVENT_ACTION_CREATE_PAYMENT, {
                page_path: EVENT_ACTION_CREATE_PAYMENT,
            });
            createPayment()
        } else {
            navigate('/login?mode=register')
        }
    }


    return (
        <div
            className="no-scrollbar  overflow-y-auto flex flex-col pt-[5%] justify-start items-center h-full w-screen bg-dark-bg lg:pl-[10%] lg:pr-[10%] pl-[5%] pr-[5%]">
            <div
                className='no-scrollbar flex flex-col justify-center items-center bg-gray  w-full bg-dark-bg rounded-[20px] pb-[10px] pt-[10px]  pb-[10%]'>
                <ProBenefitInfo></ProBenefitInfo>
                <ProductList></ProductList>
                <Button
                    placeholder={''}
                    onClick={() => {
                        openPay()
                    }}
                    className="w-[90%] lg:w-[80%] lg:w-[50%] bg-gradient-to-r normal-case from-blue-500 to-pink-500 flex flex-row justify-center items-center text-[22px] bg-gray  border-gray border-2 rounded-[10px] h-[50px] mt-[30px]">
                    <div className="flex gap-8">
                        {(paymentStatus === 'creating') &&
                            <Spinner className="h-6 w-6 text-gray-900/50 mr-[10px]" color="purple"/>
                        }
                    </div>
                    <div>{t('subscribe')}</div>
                </Button>
                <div className='text-gray-d text-[12px] mt-[10px]'>{t('subscribe_desc')}</div>
                <TeamsService></TeamsService>
            </div>

            <div className='flex justify-center flex-row text-white font-bold text-3xl mt-[100px]'>{t('faq')}</div>
            <FAQ></FAQ>
        </div>)
}