import React, {useState} from 'react';
import {
    Tabs,
    TabsHeader,
    TabsBody,
    Tab,
    TabPanel,
} from "@material-tailwind/react";
import {PromptTagEntity} from "../../model/create/PromptTagEntity";
import {PromptTag} from "../PromptTag";
import {useCreateStores} from "../../stores/CreateStores";
import {CreateTabEntity} from "../../model/config/CreateTabEntity";
import { useTranslation } from 'react-i18next';

interface TabModel {
    label: string,
    value: string,
    tags: PromptTagEntity[]
}

export function TagSelector() {
    const [activeTab, setActiveTab] = React.useState("0");
    const {tabs, addPromptTag} = useCreateStores((state) => ({
        tabs: state.tabs,
        addPromptTag: state.addPromptTag
    }))
    const data: TabModel[] = []
    tabs.map((item, index) => {
        const tab: TabModel = {label: item.name, value: '' + index, tags: item.tags}
        data.push(tab)
    })
    const handleClick = (tag: PromptTagEntity) => {
        addPromptTag(tag)
    }
    const {t} = useTranslation()
    return (<div
        className="flex flex-col w-full h-500 border-gray border-2 rounded-[10px]   mt-[15px]">

        <div className="flex flex-col justify-center  bg-transparent w-[200px] bg-dark-bg mt-[10px]">
            <div className="text-white font-bold text-[20px] select-none">{t('choose_some_tags')}</div>
        </div>
        <Tabs value={activeTab} className="mt-[10px] pl-[10px] pr-[10px]">
            <TabsHeader
                placeholder={''}
                className="rounded-none   bg-transparent p-0"
                indicatorProps={{
                    className:
                        "bg-transparent border-b-2 border-primary-color shadow-none rounded-none ",
                }}
            >
                {data.map((item: any) => (
                    <Tab
                        placeholder={''}
                        key={item.value}
                        value={item.value}
                        onClick={() => setActiveTab(item.value)}
                        className={activeTab === item.value ? "text-white font-bold w-[100px]" : "text-gray-d font-bold  w-[100px]"}
                    >
                        {item.label}
                    </Tab>
                ))}
            </TabsHeader>
            <TabsBody
                placeholder={''}
            >
                {data.map((item: TabModel) => {
                    return <TabPanel key={item.value} value={item.value}
                                     className="py-0 pt-[5px] pb-[5px]"
                    >
                        <div
                            className="flex flex-wrap flex-row  mt-[5px] mb-[10px]">
                            {item && item.tags && item.tags.map((item) =>
                                <PromptTag onClick={() => handleClick(item)} tagEntity={item}
                                           showAdd={false}></PromptTag>
                            )}</div>
                    </TabPanel>
                })}

            </TabsBody>
        </Tabs>

    </div>)
}
